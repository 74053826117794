/**
 * sequences of Get fns make up your
 * property accessor.
 */
type Get<In, Out> = (o: NonNullable<In>) => Out;

/**
 * extracts undefined or null from any type.
 * This is useful because if _any_ Get fn
 * could return null or undefined, then
 * your final result _may_ _be_ null or undefined.
 * For a more detailed explanation of why this is useful,
 * see the documentation at the end of the get
 * type declarations.
 */
type Nil<T> = Extract<T, undefined | null>;

//#region Type Tests
/**
 * This region is designed to
 * verify the correctness of the behavior
 * of the Nil type.
 */
/** (string) extends (null | undefined) === (never) */
type Never = Nil<string>;
/** (string | undefined) extends (null | undefined) === (undefined) */
type Undefined =  Nil<string | undefined>;
/** (string | null) extends (null | undefined) === (null) */
type Null = Nil<string | null>;
/** (string | null | undefined) extends (null | undefined) === (null | undefined) */
type NullOrUndefined = Nil<string | null | undefined>;

/**
 * These assert the above comments hold. They will throw ts
 * errors if any are untrue.
 */
null as any as never as Never;
null as Null;
null as NullOrUndefined;
undefined as NullOrUndefined;
undefined as Undefined;
//#endregion Type Tests

export function get<In, Out>(
	In: In,
	fOut: Get<In, Out>
): Out | Nil<In>;
export function get<In, A, Out>(
	In: In,
	fA: Get<In, A>,
	fOut: Get<A, Out | Nil<In | A>>
): Out | Nil<In | A>;
export function get<In, A, B, Out>(
	In: In,
	fA: Get<In, A>,
	fB: Get<A, B>,
	fOut: Get<B, Out | Nil<A | B>>
): Out | Nil<In | A | B>;
export function get<In, A, B, C, Out>(
	In: In,
	fA: Get<In, A>,
	fB: Get<A, B>,
	fC: Get<B, C>,
	fOut: Get<C, Out | Nil<A | B | C>>
): Out | Nil<In | A | B | C>;
export function get<In, A, B, C, D, Out>(
	In: In,
	fA: Get<In, A>,
	fB: Get<A, B>,
	fC: Get<B, C>,
	fD: Get<C, D>,
	fOut: Get<D, Out | Nil<A | B | C | D>>
): Out | Nil<In | A | B | C | D>;
export function get<In, A, B, C, D, E, Out>(
	In: In,
	fA: Get<In, A>,
	fB: Get<A, B>,
	fC: Get<B, C>,
	fD: Get<C, D>,
	fE: Get<D, E>,
	fOut: Get<E, Out | Nil<A | B | C | D | E>>
): Out | Nil<In | A | B | C | D | E>;
export function get<In, A, B, C, D, E, F, Out>(
	In: In,
	fA: Get<In, A>,
	fB: Get<A, B>,
	fC: Get<B, C>,
	fD: Get<C, D>,
	fE: Get<D, E>,
	fF: Get<E, F>,
	fOut: Get<F, Out | Nil<A | B | C | D | E | F>>
): Out | Nil<In | A | B | C | D | E | F>;
export function get<In, A, B, C, D, E, F, G, Out>(
	In: In,
	fA: Get<In, A>,
	fB: Get<A, B>,
	fC: Get<B, C>,
	fD: Get<C, D>,
	fE: Get<D, E>,
	fF: Get<E, F>,
	fG: Get<F, G>,
	fOut: Get<G, Out | Nil<A | B | C | D | E | F | G>>
): Out | Nil<In | A | B | C | D | E | F | G>;
export function get<In, A, B, C, D, E, F, G, H, Out>(
	In: In,
	fA: Get<In, A>,
	fB: Get<A, B>,
	fC: Get<B, C>,
	fD: Get<C, D>,
	fE: Get<D, E>,
	fF: Get<E, F>,
	fG: Get<F, G>,
	fH: Get<G, H>,
	fOut: Get<H, Out | Nil<A | B | C | D | E | F | G | H>>
): Out | Nil<In | A | B | C | D | E | F | G | H>;

/**
 * Why do we use return type of Out | Nil<In, A | B ... | Z>?
 *
 * This is so the return type is such that:
 *
 * 		typeof ReturnType<get(o, fA, ... fZ, fOut)> =
 * 			Out
 * 			| (some o nor A-Z extends null ? null | never)
 * 			| (some o nor A-Z extends undefined ? undefined | never)
 *
 * So the return type reduces to Out if there is no o nor A-Z
 * which may return undefined or null.
 *
 * In other words, the return type of get may be undefined if and
 * only if some return type of a passed function included undefined.
 *
 * Likewise, the return type of get may be null if and
 * only if some return type of a passed function included null.
 *
 * But what is the practical purpose of doing this? Consider the following:
 *
 * 		interface IEntity: {
 *			propWithNullable?: {
 *				nullable: null | {
 *					prop: string;
 *				};
 *			}
 *		};
 * 		const entity: IEntity = {};
 * 		const result: string = get(entity,
 * 			o => o.propWithNullable,
 * 			o => o.nullable,
 * 			o => o.prop
 * 		);
 *
 * With a return type of simply Out, the above does not error.
 * However, we realize that it should. The first Get fn will
 * fail and the returned result will be undefined, not string.
 *
 * The naive solution is to make every fOut return Out | undefined.
 * However, it is not necessarily true that every fOut may return undefined.
 * There are cases where the value will certainly be there, such as when
 * using Get fns that calculate or provide defaults. For example:
 *
 * 		// get with a default
 * 		interface IEntity: { optionalString?: string };
 * 		const entity: IEntity = {};
 * 		const result: string = get(entity, o => o.optionalString || 'default');
 *
 * If fOut is typed with return type of Out | undefined, then we cannot
 * definitely assign it to string. However, Out | Nil<In | A> reduces to Out.
 * As a result, the return type is string | Nil<IEntity | string>, or simply, string.
 *
 * You can also see this is useful when using get to perform a series
 * of calculations where no return value is possibly undefined.
 * For example, the get below cannot possibly return undefined:
 *
 * 		// get with calculations
 * 		const date: Moment = get(entity,
 * 			o => o.Duration || 0,
 * 			o => moment.duration({ milliseconds: o }),
 * 			o => moment().add(o)
 * 		);
 *
 * Out | Nil<o | A-Z> allows us to type such calls correctly.
 */

/**
 * get in a TypeSafe manner up to 9 property levels deep.
 * If you need more levels, add to this function or re-write your code
 * so that you don't.
 * @param o the object to deconstruct
 * @param functions The functions that will extract properties in a chain
 * @returns your property or undefined
 */
export function get(
	o: any,
	...functions: Array<Get<any,any>>
): any | undefined {
	function reducer(prev: any, f: Get<any, any>) {
		if (prev === null)
			return null;
		if (prev === undefined)
			return undefined;
		if (f === undefined)
			return prev;
		return f(prev);
	}
	return functions.reduce(reducer, o);
}
export default get;