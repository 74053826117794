import { NonObject, O } from './types';
import {
	isNullOrUndefined,
	isString,
	isBoolean,
	isNumber,
	isFunction,
	isUndefined,
} from 'util';

/** is a field present */
export const isSet = (o: any) => {
	if (isNullOrUndefined(o)) return false;
	if (Array.isArray(o)) return o.length > 0;
	if (isString(o)) return o.trim() !== '';
	return true;
};

/** await this function to delay execution */
export function delay(durationMs: number) {
	return new Promise(r => setTimeout(r, durationMs));
}

/** check if this is a non-object */
export function isNonObject(o: any): o is NonObject {
	return (
		isNullOrUndefined(o) ||
		isString(o) ||
		isBoolean(o) ||
		isNumber(o) ||
		isFunction(o)
	);
}

/**
 * recursively traverses the object and returns a new
 * object with no undefined keys.
 * keeps falsey or empty values, but never undefined
 */
export function sanitize(o: O<O<any> | NonObject>) {
	const result: O<any> = {};
	Object.keys(o).forEach(key => {
		const value = o[key];
		if (isUndefined(value)) {
			return;
		}
		if (!isNonObject(value)) {
			result[key] = sanitize(value);
		} else {
			result[key] = value;
		}
	});
	return result;
}
